<template>
  <v-container
    v-if="company"
    :class="{'pa-0':$vuetify.breakpoint.smAndDown}"
  >
    <v-stepper
      v-model="step"
      non-linear
      alt-labels
      class="rounded-lg"
      outlined
    >
      <v-tooltip
        bottom
        :disabled="!isDirty"
      >
        <template v-slot:activator="{ on }">
          <div v-on="on">
            <v-stepper-header
              class="flex-nowrap"
            >
              <template
                v-for="({route, title, complete}, index) in steps"
              >
                <v-stepper-step
                  :key="route"
                  :editable="!isDirty"
                  :complete="complete"
                  edit-icon="$complete"
                  :step="index + 1"
                  :class="{'mobile-stepper':$vuetify.breakpoint.smAndDown}"
                >
                  {{ title }}
                </v-stepper-step>
                <v-divider
                  v-if="index !== steps.length - 1"
                  :key="index"
                  :class="{'mobile-divider':$vuetify.breakpoint.smAndDown}"
                />
              </template>
            </v-stepper-header>
          </div>
        </template>
        <span>
          {{ $t('onboarding.tooltip.saveChanges') }}
        </span>
      </v-tooltip>

      <v-stepper-items>
        <v-stepper-content
          v-if="hasCompanyStep"
          :step="getStep('company')"
        >
          <company-data
            class="px-4"
            v-bind="companyDataProps"
            is-onboarding
            @nextStep="handleNextStep"
            @dirty="handleDirty"
            @missingData="(value) => handleMissingData(value, getStep('company') - 1)"
          />
        </v-stepper-content>

        <v-stepper-content
          v-if="hasBillingStep"
          :step="getStep('payment')"
        >
          <billing-data
            class="px-4 pt-2"
            v-bind="billingDataProps"
            is-onboarding
            @nextStep="handleNextStep"
            @dirty="handleDirty"
            @missingData="(value) => handleMissingData(value, getStep('payment') - 1)"
          />
        </v-stepper-content>

        <v-stepper-content
          v-if="hasConctactStep"
          :step="getStep('contact')"
        >
          <contacts
            class="px-4"
            v-bind="contactDataProps"
            is-onboarding
            @nextStep="handleNextStep"
            @dirty="handleDirty"
            @missingData="(value) => handleMissingData(value, getStep('contact') - 1)"
          />
        </v-stepper-content>

        <v-stepper-content
          v-if="hasPublicationStep"
          :step="getStep('publication')"
        >
          <publication
            class="px-4"
            v-bind="publicationDataProps"
            is-onboarding
            @nextStep="handleNextStep"
            @dirty="handleDirty"
            @missingData="(value) => handleMissingData(value, getStep('publication') - 1)"
          />
        </v-stepper-content>

        <v-stepper-content
          v-if="getStep('guide')"
          :step="getStep('guide')"
        >
          <Landingpages
            class="px-4"
            :count-of-guides="countOfGuides"
            v-bind="landingpageDataProps"
            @nextStep="handleNextStep"
            @dirty="handleDirty"
            @missingData="(value) => handleMissingData(value, getStep('guide') - 1)"
          />
        </v-stepper-content>

        <v-stepper-content
          v-if="hasEmailValidationStep"
          :step="getStep('email-validation')"
        >
          <EmailValidation
            class="px-4"
            :email="company.ambassador.email"
            :company-name="company.name"
            @nextStep="handleNextStep"
            @missingData="(value) => handleMissingData(value, getStep('email-validation') - 1)"
          />
        </v-stepper-content>

        <v-stepper-content
          v-if="hasValidationStep"
          :step="getStep('validation')"
        >
          <submit
            class="px-4"
            :is-complete="isComplete"
            :company-id="company.id"
            :missing-data="missingData"
          />
        </v-stepper-content>
      </v-stepper-items>
    </v-stepper>
  </v-container>
</template>

<script>
import CompanyData from '@/modules/company/CompanyData'
import BillingData from '@/modules/company/BillingData'
import Contacts from '@/modules/company/Contacts'
import Publication from '@/modules/company/Publication'
import Submit from './Submit.vue'
import COMPANY from '@/modules/company/queries/Company.gql'
import GET_COMPONENT_SUBSCRIPTIONS_COUNT_FOR_COMPANY from '@/modules/billwerk/subscribe/queries/getComponentSubscriptionsCountBySlug.gql'
import Landingpages from './Landingpages.vue'
import EmailValidation from './EmailValidation.vue'
import featureMixin from '@/mixins/feature'

const PREVIEWS = {
  ATV1: 'https://static.bottimmo.com/assets/onboarding/previews/A4C_Ratgeber-Vorschau/Ratgeber-Vorschau.pdf',
  CHV1: 'https://static.bottimmo.com/assets/onboarding/previews/C0X_Ratgeber-Vorschau/Ratgeber-Vorschau.pdf',
  CHV2: 'https://static.bottimmo.com/assets/onboarding/previews/C3BE_Ratgeber-Vorschau/Ratgeber-Vorschau.pdf',
  DV1: 'https://static.bottimmo.com/assets/onboarding/previews/D4AL_Ratgeber-Vorschau/Ratgeber-Vorschau.pdf',
  DV2: 'https://static.bottimmo.com/assets/onboarding/previews/D6EM_Ratgeber-Vorschau/Ratgeber-Vorschau.pdf',
  DV3: 'https://static.bottimmo.com/assets/onboarding/previews/D8QT_Ratgeber-Vorschau/Ratgeber-Vorschau.pdf',
  DV4: 'https://static.bottimmo.com/assets/onboarding/previews/D91RL_Ratgeber-Vorschau/Ratgeber-Vorschau.pdf',
  REMAX: 'https://static.bottimmo.com/assets/onboarding/previews/R13PO_Ratgeber-Vorschau/Ratgeber-Vorschau.pdf',
  VOBA: 'https://static.bottimmo.com/assets/onboarding/previews/V99F7_Ratgeber-Vorschau/Ratgeber-Vorschau.pdf'
}

export default {
  components: { CompanyData, BillingData, Contacts, Publication, Submit, Landingpages, EmailValidation },
  mixins: [featureMixin],
  data () {
    return {
      step: -1,
      isDirty: false,
      missingData: {}
    }
  },
  computed: {
    countOfGuides () {
      return this.getComponentSubscriptionsCountBySlug + 1
    },
    isComplete () {
      return this.steps?.slice(0, -1).every(({ complete }) => complete)
    },
    hasCompanyStep () {
      return true
    },
    hasBillingStep () {
      return this.isFeatureActive(this.featureNames.CONTRACT)
    },
    hasConctactStep () {
      return true
    },
    hasPublicationStep () {
      return true
    },
    hasGuideStep () {
      return this.isFeatureActive(this.featureNames.LANDINGPAGE) && this.getFeature(this.featureNames.LANDINGPAGE)?.config.canBookLandingpages
    },
    hasEmailValidationStep () {
      return this.getFeature(this.featureNames.HOPPERMATION)?.isActive
    },
    hasValidationStep () {
      return true
    },
    steps () {
      const steps = [
        { route: 'company', title: this.$t('onboarding.steps.company'), complete: this.missingData?.company?.length === 0, disabled: !this.hasCompanyStep },
        { route: 'payment', title: this.$t('onboarding.steps.payment'), complete: this.missingData?.payment?.length === 0, disabled: !this.hasBillingStep },
        { route: 'contact', title: this.$t('onboarding.steps.contact'), complete: this.missingData?.contact?.length === 0, disabled: !this.hasConctactStep },
        { route: 'publication', title: this.$t('onboarding.steps.publication'), complete: this.missingData?.publication?.length === 0, disabled: !this.hasPublicationStep },
        { route: 'guide', title: this.$t('onboarding.steps.guide'), complete: this.missingData?.guide?.length === 0, disabled: !this.hasGuideStep },
        { route: 'email-validation', title: this.$t('onboarding.steps.emailValidation'), complete: this.missingData.emailValidation?.length === 0, disabled: !this.hasEmailValidationStep },
        { route: 'validation', title: this.$t('onboarding.steps.validation'), complete: this.missingData.validation?.length === 0, disabled: !this.hasValidationStep }
      ]
      return steps
        .filter(({ disabled }) => !disabled)
        .map((step, index) => ({ ...step, step: index + 1 }))
    },
    companyDataProps () {
      const { id: companyId, name, address, links } = this.company
      return { companyId, name, address, links }
    },
    billingDataProps () {
      const { id: companyId, country, billing, customerNumber, address, name } = this.company
      return {
        companyId,
        country,
        address,
        name,
        customerNumber,
        billing: {
          ...billing,
          address: billing.address || {}
        }
      }
    },
    contactDataProps () {
      return { companyId: this.company.id }
    },
    publicationDataProps () {
      const {
        id: companyId, slogan, ambassador, name, links, address, mailProvider,
        images: { team, logo, office, region },
        variant,
        theme,
        isReferenceCustomer
      } = this.company
      ambassador.image = ambassador.image || {}
      theme.colors.primary = theme.colors.primary || '#000000'
      theme.colors.secondary = theme.colors.secondary || '#000000'
      const previewLink = PREVIEWS[variant]
      return {
        companyId,
        slogan,
        ambassador,
        name,
        links,
        address,
        mailProvider,
        previewLink,
        theme,
        isReferenceCustomer,
        images: {
          team: team || {},
          logo: logo || {},
          office: office || {},
          region: region || {}
        }
      }
    },
    landingpageDataProps () {
      const {
        id: companyId,
        variant,
        country,
        bookedLandingpageSlugs
      } = this.company
      return {
        companyId,
        variant,
        country,
        bookedLandingpageSlugs
      }
    }
  },
  watch: {
    step: {
      immediate: true,
      handler (step) {
        if (!this.$route.path.match(/onboarding/)) return
        const stepIndex = this.steps.findIndex(step => step.route === this.$route.params.step) + 1
        if (stepIndex !== step && step !== -1) {
          const route = this.steps[step - 1].route
          this.$router.push('/onboarding/' + route)
        }
      }
    }
  },
  created () {
    this.step = (this.steps.findIndex(step => step.route === this.$route.params.step) || 0) + 1
  },
  methods: {
    getStep (route) {
      return this.steps.find((step) => step.route === route)?.step
    },
    handleNextStep (value = 1) {
      const nextStep = this.step + value
      this.step = nextStep < 1 ? 1 : nextStep
    },
    handleDirty (value) {
      this.isDirty = value
    },
    handleMissingData (missingData, step) {
      this.missingData = { ...this.missingData, [this.steps[step].title]: missingData }
    }
  },
  apollo: {
    company: {
      query: COMPANY,
      variables () {
        return {
          id: this.$auth.user.companyId
        }
      }
    },
    getComponentSubscriptionsCountBySlug: {
      query: GET_COMPONENT_SUBSCRIPTIONS_COUNT_FOR_COMPANY,
      variables () {
        return {
          input: {
            slug: 'Ratgeber',
            companyId: this.$auth.user.companyId
          }
        }
      }
    }
  }
}
</script>

<style>
.mobile-stepper {
  max-width: 50px;
}

.mobile-divider {
  max-width: 15px;
}
</style>
