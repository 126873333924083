<template>
  <div>
    <div class="pb-4 mb-2">
      <h2 class="pr-4 ml-n2">
        {{ $t('onboarding.company-data.address.title') }}
      </h2>
    </div>
    <v-row>
      <v-text-field
        ref="name"
        v-model.lazy="data.name"
        outlined
        :label="$t('onboarding.company-data.address.name')"
        :placeholder="$t('onboarding.company-data.address.namePlaceholder')"
        :rules="[rules.length({max: 47}), rules.optionalForOnboarding(isOnboarding) ]"
        validate-on-blur
      />
    </v-row>
    <v-row>
      <v-text-field
        ref="street"
        v-model.lazy="data.street"
        outlined
        :label="$t('onboarding.company-data.address.street')"
        :placeholder="$t('onboarding.company-data.address.streetPlaceholder')"
        :rules="[rules.optionalForOnboarding(isOnboarding)]"
        validate-on-blur
      />
    </v-row>
    <v-row class="d-flex justify-space-between">
      <v-col
        cols="12"
        md="3"
        class="pr-0 pa-0 pr-md-2"
      >
        <v-text-field
          ref="zip"
          v-model.lazy="data.zip"
          outlined
          :label="$t('onboarding.company-data.address.zip')"
          type="number"
          :placeholder="$t('onboarding.company-data.address.zipPlaceholder')"
          :rules="[rules.zip, rules.length({min: 4, max: 5}), rules.optionalForOnboarding(isOnboarding)]"
          validate-on-blur
        />
      </v-col>
      <v-col
        cols="12"
        md="6"
        class="pr-0 pa-0 pr-md-2"
      >
        <v-text-field
          ref="city"
          v-model.lazy="data.city"
          outlined
          :label="$t('onboarding.company-data.address.city')"
          :placeholder="$t('onboarding.company-data.address.cityPlaceholder')"
          :rules="[rules.optionalForOnboarding(isOnboarding)]"
          validate-on-blur
        />
      </v-col>
      <v-col
        cols="12"
        md="3"
        class="pa-0"
      >
        <v-select
          ref="country"
          v-model.lazy="data.country"
          outlined
          :label="$t('onboarding.company-data.address.country')"
          validate-on-blur
          :items="availableCountryItems"
          :rules="[rules.optionalForOnboarding(isOnboarding)]"
        />
      </v-col>
    </v-row>
    <div class="pb-4 mb-2">
      <h2 class="pr-4 ml-n2">
        {{ $t('onboarding.company-data.web.title') }}
      </h2>
    </div>
    <v-row class="d-flex justify-space-between">
      <v-col
        cols="12"
        md="6"
        class="pr-0 pa-0 pr-md-2"
      >
        <v-text-field
          ref="website"
          v-model.lazy="data.website"
          outlined
          type="url"
          :label="$t('onboarding.company-data.web.website')"
          :placeholder="$t('onboarding.company-data.web.websitePlaceholder')"
          :rules="[rules.url, rules.optionalForOnboarding(isOnboarding)]"
          validate-on-blur
        />
      </v-col>
      <v-col
        cols="12"
        md="6"
        class="pa-0"
      >
        <v-text-field
          ref="about"
          v-model.lazy="data.about"
          type="url"
          outlined
          :label="$t('onboarding.company-data.web.about')"
          :placeholder="$t('onboarding.company-data.web.aboutPlaceholder')"
          :rules="[rules.url, rules.optionalForOnboarding(isOnboarding)]"
          validate-on-blur
        />
      </v-col>
    </v-row>
    <v-row class="d-flex justify-space-between">
      <v-col
        cols="12"
        md="6"
        class="pr-0 pa-0 pr-md-2"
      >
        <v-text-field
          ref="imprint"
          v-model.lazy="data.imprint"
          type="url"
          outlined
          :label="$t('onboarding.company-data.web.imprint')"
          :placeholder="$t('onboarding.company-data.web.imprintPlaceholder')"
          :rules="[rules.url, rules.optionalForOnboarding(isOnboarding)]"
          validate-on-blur
        />
      </v-col>
      <v-col
        cols="12"
        md="6"
        class="pa-0"
      >
        <v-text-field
          ref="privacy"
          v-model.lazy="data.privacy"
          type="url"
          outlined
          :label="$t('onboarding.company-data.web.privacy')"
          :placeholder="$t('onboarding.company-data.web.privacyPlaceholder')"
          :rules="[rules.url, rules.optionalForOnboarding(isOnboarding)]"
          validate-on-blur
        />
      </v-col>
    </v-row>

    <div class="pb-4 mb-2">
      <h2 class="pr-4 ml-n2">
        {{ $t('onboarding.company-data.additional.title') }}
      </h2>
    </div>

    <v-row class="d-flex justify-space-between">
      <v-col
        cols="12"
        md="6"
        class="pa-0"
        :class="{'pr-0': $vuetify.breakpoint.smAndDown, 'pr-2': $vuetify.breakpoint.mdAndUp}"
      >
        <v-text-field
          ref="commission"
          v-model.lazy="data.commission"
          type="number"
          min="0"
          max="100"
          suffix="%"
          outlined
          :label="$t('onboarding.company-data.additional.commission')"
          :placeholder="$t('onboarding.company-data.additional.commissionPlaceholder')"
          validate-on-blur
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { zip, url, length, optionalForOnboarding } from '@/lib/validation'
import countries from '@/mixins/countries'

export default {
  mixins: [countries],
  props: {
    data: {
      type: Object,
      default: () => ({})
    },
    isOnboarding: {
      type: Boolean,
      default: false
    }
  },
  created () {
    this.rules = { zip, url, length, optionalForOnboarding }
  }
}
</script>
