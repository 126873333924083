<template>
  <div class="pa-4">
    <v-row class="justify-center d-flex">
      <v-col
        cols="12"
        md="8"
        class="d-flex flex-column"
      >
        <Feature
          v-slot="{feature}"
          :feature-slug="featureNames.DATA_PRIVACY"
        >
          <div v-if="feature.config.hasConditions">
            <div>
              <h2 class="pr-4 d-inline">
                {{ $t('onboarding.submit.termsAndPrivacy') }}
              </h2>
            </div>
            <div
              class="d-flex"
              :class="{'flex-column align-start': $vuetify.breakpoint.smAndDown, 'align-end': $vuetify.breakpoint.mdAndUp}"
            >
              <v-checkbox
                v-model="acceptAgb"
                hide-details
                :label="$t('onboarding.submit.acceptTerms')"
              />
              <div class="pl-8 pl-md-1">
                <a
                  :href="feature.config.conditionsUrl"
                  target="_blank"
                  rel="noopener noreferrer"
                > {{ $t('onboarding.submit.termsOf', { company: legalCompanyName }) }}</a>
              </div>
            </div>
          </div>
        </Feature>

        <div
          class="d-flex"
          :class="{'flex-column align-start': $vuetify.breakpoint.smAndDown, 'align-end': $vuetify.breakpoint.mdAndUp}"
        >
          <v-checkbox
            v-model="acceptPrivacy"
            hide-details
            :label="$t('onboarding.submit.acceptPrivacy')"
          />
          <div class="pl-8 pl-md-1">
            <a
              class="px-2"
              target="_blank"
              rel="noopener noreferrer"
              :href="dataPrivacyUrl"
            > {{ $t('onboarding.submit.privacyPolicyOf', { company: legalCompanyName }) }}</a>
          </div>
        </div>

        <Feature
          v-slot="{feature}"
          :feature-slug="featureNames.MATERIAL_GUIDE"
        >
          <div
            class="d-flex"
            :class="{'flex-column align-start': $vuetify.breakpoint.smAndDown, 'align-end': $vuetify.breakpoint.mdAndUp}"
          >
            <v-checkbox
              v-model="acceptGuideline"
              hide-details
              :label="$t('onboarding.submit.acceptGuideline')"
            />
            <div class="pl-8 pl-md-1">
              <a
                :href="feature.config.guideUrl"
                target="_blank"
                rel="noopener noreferrer"
              > {{ $t('onboarding.submit.guidelineFor', { platform: readablePlatformName }) }}</a>
              {{ $t('onboarding.submit.received') }}
            </div>
          </div>
        </Feature>

        <v-checkbox
          v-model="acceptOnboarding"
          :label="$t('onboarding.submit.confirmAllData')"
          class="onboarding-agreement"
        />
        <div class="d-flex flex-column align-center">
          <v-btn
            class="ma-4"
            :class="{'mx-0 btn-multiline': $vuetify.breakpoint.smAndDown, 'mx-16': $vuetify.breakpoint.mdAndUp}"
            :height="$vuetify.breakpoint.smAndDown ? 80 : 35"
            color="primary"
            :disabled="!accept"
            @click="submit"
          >
            <span class="text-wrap">
              {{ $t('onboarding.submit.completeOnboarding') }}
            </span>
          </v-btn>
          <v-alert
            v-if="error && !isComplete"
            type="error"
            tile
            text
          >
            {{ error }}
            <ul>
              <li
                v-for="([step, missing]) in filterdMissingData"
                :key="step"
              >
                {{ step + ': ' + missing.map((field) => $t(`onboarding.submit.fields.${field}`)).join(', ') }}
              </li>
            </ul>
          </v-alert>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import SUBMIT_ONBOARDING from './queries/SubmitOnboarding.gql'
import bus, { eventNames } from '@/lib/eventBus'
import brandingMixin from '@/mixins/branding'
import featureMixin from '@/mixins/feature'

export default {
  mixins: [brandingMixin, featureMixin],
  props: {
    isComplete: {
      type: Boolean,
      required: true
    },
    companyId: {
      type: String,
      required: true
    },
    missingData: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      acceptAgb: false,
      acceptPrivacy: false,
      acceptGuideline: false,
      acceptOnboarding: false,
      error: ''
    }
  },
  computed: {
    filterdMissingData () {
      return Object.entries(this.missingData).filter(([key, value]) => value.length > 0)
    },
    accept () {
      const acceptAgb = !this.isFeatureActive(this.featureNames.DATA_PRIVACY) ||
          !this.getFeature(this.featureNames.DATA_PRIVACY).hasConditions ||
          this.acceptAgb
      const acceptGuideline = !this.isFeatureActive(this.featureNames.MATERIAL_GUIDE) ||
          this.acceptGuideline
      return [acceptAgb, this.acceptPrivacy, acceptGuideline, this.acceptOnboarding].every(Boolean)
    }
  },
  methods: {
    async submit () {
      if (!this.isComplete) {
        this.error = this.$t('onboarding.submit.completeAllSteps')
        return
      }
      await this.$apollo.mutate({
        mutation: SUBMIT_ONBOARDING,
        variables: { companyId: this.companyId }
      })
      this.error = ''
      bus.$emit(eventNames.TRIGGER_ANIMATION)

      this.$router.go('/onboarding-validation')
    }
  }
}
</script>

<style>
.onboarding-agreement > * .v-input__slot {
  align-items:start !important;
}
</style>
