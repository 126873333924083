<template>
  <div>
    <v-color-picker
      v-model.lazy="model"
      mode="hexa"
      hide-mode-switch
      v-on="$listeners"
    />
    <v-input
      class="hidden"
      type="number"
      :value="brightness"
      :rules="[hasSufficientContrast]"
    />
    <v-alert
      v-if="brightness > maxBrightness"
      type="error"
      text
    >
      {{ $t('colorPicker.lowContrastAlert') }}
    </v-alert>
    <v-alert
      v-else
      dark
      class="font-weight-bold"
      :color="model"
    >
      {{ $t('colorPicker.sufficientContrastAlert') }}
    </v-alert>
  </div>
</template>

<script>
export default {
  props: {
    maxBrightness: {
      type: Number,
      default: 186
    },
    value: {
      type: String,
      default: '#000000'
    }
  },
  data () {
    return {
      model: this.value
    }
  },
  computed: {
    brightness () {
      const { red, green, blue } = this.hexToRgb(this.model)
      return red * 0.299 + green * 0.587 + blue * 0.114
    }
  },
  methods: {
    hexToRgb (hex) {
      var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex)
      return result ? {
        red: parseInt(result[1], 16),
        green: parseInt(result[2], 16),
        blue: parseInt(result[3], 16)
      } : {
        red: 0,
        green: 0,
        blue: 0
      }
    },
    hasSufficientContrast () {
      return this.brightness <= this.maxBrightness
    }
  }
}
</script>
